<template>
  <div>
    <b-modal
      id="edit-material-variant"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('Edit')} ` + idMat.materialVariantName"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Name')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model.trim="form.materialVariantName"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            {{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          :label="`${$t('Description')}:`"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="form.description"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            {{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          :label="`${$t('TotalStockAmount')}:`"
          label-for="input-3"
        >
          <b-form-input
            id="input-3"
            v-model="form.totalStockAmount"
            step="any"
            type="number"
            aria-describedby="input-1-live-feedback"
            readonly
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{ $t('This_is_a_required_field_and_must_be_at_least_0') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-4"
          :label="`${$t('MinimalStockQuantity')}:`"
          label-for="input-4"
        >
          <b-form-input
            id="input-4"
            v-model="form.minimalStockAmount"
            step="any"
            type="number"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{ $t('This_is_a_required_field_and_must_be_at_least_0') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-5"
          :label="`${$t('PricePerMeter')}:`"
          label-for="input-5"
        >
          <b-form-input
            id="input-5"
            v-model="form.pricePerMeter"
            required
            type="number"
            step="any"
            aria-describedby="input-1-live-feedback"
            placeholder="€"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{ $t('This_is_a_required_field_and_must_be_at_least_0') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-5"
          :label="`${$t('SellPrice')}:`"
          label-for="input-5"
        >
          <b-form-input
            id="input-5"
            v-model="form.sellPrice"
            required
            type="number"
            step="any"
            placeholder="€"
          />
        </b-form-group>
        <b-form-group
          id="input-group-6"
          :label="`${$t('Daystodeliver')}:`"
          label-for="input-6"
        >
          <b-form-input
            id="input-6"
            v-model="form.daysToDeliver"
            type="number"
            required
            :placeholder="$t('Days')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{ $t('This_is_a_required_field_and_must_be_at_least_0') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-select-1"
          :label="`${$t('MainSupplier')}:`"
          label-for="select-1"
        >
          <vue-select
            v-model="form.mainSupplierName"
            :options="getFilteredSupplierInSelect.length == 0 ? getSuppliers: getFilteredSupplierInSelect"
            :placeholder="$t('SelectSupplier') "
            label="supplierName"
            aria-describedby="input-1-live-feedback"
            @search="onSearchSuppliers"
          >
            <li
              slot="list-footer"
              class="pagination"
              style="display: flex; width: 100%; justify-content: center"
            >
              <button
                type="button"
                :style="pageP == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                :disabled="pageP == 1"
                @click="prevSuppliers"
              >
                {{ $t('Prev') }}
              </button>
              <button
                type="button"
                :style="pageP == getTotalItemsForSuppliers ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                :disabled="pageP == getTotalItemsForSuppliers"
                @click="nextSuppliers"
              >
                {{ $t('Next') }}
              </button>
            </li>
          </vue-select>

        </b-form-group>
        <b-form-group
          id="input-select-2"
          :label="`${$t('SecondarySupplier')}:`"
          label-for="select-2"
        >
          <vue-select
            v-model="form.secondSupplierName"
            :options="getFilteredSupplierInSelect.length == 0 ? getSuppliers: getFilteredSupplierInSelect"
            aria-describedby="input-1-live-feedback"
            :placeholder="$t('SelectSupplier') "
            label="supplierName"
            @search="onSearchSuppliers"
          >
            <li
              slot="list-footer"
              class="pagination"
              style="display: flex; width: 100%; justify-content: center"
            >
              <button
                type="button"
                :style="pageP == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                :disabled="pageP == 1"
                @click="prevSuppliers"
              >
                {{ $t('Prev') }}
              </button>
              <button
                type="button"
                :style="pageP == getTotalItemsForSuppliers ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                :disabled="pageP == getTotalItemsForSuppliers"
                @click="nextSuppliers"
              >
                {{ $t('Next') }}
              </button>
            </li>
          </vue-select>
        </b-form-group>
        <b-form-group :label="$t('SelectColor')">
          <vue-select
            v-model="form.colorName"
            :options="getColorsData"
            :reduce="e => e"
            :placeholder="$t('SelectColor') "
            label="colorName"
            @search="onSearchColors"
          >
            <li
              slot="list-footer"
              class="pagination"
              style="display: flex; width: 100%; justify-content: center; -ms-word-break: break-all; word-break: break-all; word-break: break-word;"
            >
              <button
                type="button"
                :style="pageC == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                :disabled="pageC == 1"
                @click="prevColors"
              >
                {{ $t('Prev') }}
              </button>
              <button
                type="button"
                :style="pageC == Math.ceil(getTotalItemsForColors / 10) ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                :disabled="pageC == Math.ceil(getTotalItemsForColors / 10)"
                @click="nextColors"
              >
                {{ $t('Next') }}
              </button>
            </li>
            <!-- :state="validateState('colorName')"
            aria-describedby="input-1-live-feedback" -->
            <template #selected-option="{ colorName, hashColor }">
              <div class="flex aic">
                <span
                  class="colored-circle"
                  :style="`background-color: ${hashColor}`"
                />
                <p style="-ms-word-break: break-all; word-break: break-all; word-break: break-word; margin-bottom: 0; margin-left: 10px">
                  {{ colorName }}
                </p>
              </div>
            </template>

            <template #option="{ colorName, hashColor }">
              <div class="flex aic">
                <span
                  class="colored-circle"
                  :style="`background-color: ${hashColor}`"
                />
                <p style="-ms-word-break: break-all; word-break: break-all; word-break: break-word; margin-bottom: 0; margin-left: 10px">
                  {{ colorName }}
                </p>
              </div>
            </template>
          </vue-select>
        </b-form-group>
        <b-form-group
          v-if="client !='DrenushaXharra'"
          id="input-group-4"
          :label="`${$t('Location')}:`"
        >
          <!-- :reduce="(e)=> e.locationId" -->
          <vue-select
            v-model="form.locationName"
            :options="getLocationByPagination"
            :placeholder="$t('SelectLocation')"
            label="locationName"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <b-form-group
          id="input-group-3"
          :label="`${$t('MeasurementType')}:`"
        >
          <div class="flex radio-form">
            <div class="flex aic">

              <input
                id="one"
                v-model="form.measurementType"
                type="radio"
                value="Meter"
                disabled
              >
              <label for="one">{{ $t('Meter') }}</label>
            </div>
            <div class="flex aic">
              <input
                id="two"
                v-model="form.measurementType"
                type="radio"
                disabled
                value="Part"
              >
              <label for="two">{{ $t('Part') }}</label>
            </div>
          </div>
        </b-form-group>
        <AddFile
          v-show="image.imgs.length == 0"
          ref="addFile"
          :folder="'MaterialVariantPhoto'"
          :label-text="'UploadPhoto'"
          :file-name="form.fileName"
          style="width: 200px;"
        />
        <pre
          v-show="image.imgs.length == 0"
          :class="{ 'hidden': image.imgs.length == 0}"
        />
        <div>
          <p
            v-show="isTextShown && image.imgs.length != 0"
            :class="{ 'hidden': isTextShown && image.imgs.length != 0,}"
            class="text"
            style="font-size:small; color:#242F6E"
          >
            {{ $t('ClickToViewPhoto') }}
          </p>
          <div
            style="display: flex; flex-direction: row !important;"
          >
            <div
              v-for="(img, index) in image.imgs"
              :key="img.materialVariantPhotoId"
              :class="{'second-photo': index === 1, 'third-photo': index === 2,}"
              style="background-color: gainsboro; border-radius: 8px; height: 56px; display: flex; justify-content: center; align-items: center;"
            >
              <img
                :class="{'photo': true, 'hovered': hoveredIndex === index,'second-photo': index === 1, 'third-photo': index === 2,}"
                :style="{ transform: `scale(${img.zoom})` }"
                :src="img.src"
                @click="zoomIn(index)"
                @mouseover="showText()"
                @mouseleave="zoomOut"
              >
              <b-icon-x-circle
                :key="img.materialVariantPhotoId"
                style="margin-left: 25px; margin-right: 5px;"
                @click="handleDeleteClick(img.materialVariantPhotoId)"
              />
            </div>
          </div>
        </div>
        <pre
          v-show="image.imgs.length == 0"
          :class="{ 'hidden': image.imgs.length == 0}"
        />
        <pre
          v-show="client !='DrenushaXharra'"
          :class="{ 'hidden': client !='DrenushaXharra'}"
        />
        <b-form-group v-show="image.imgs.length != 0">
          <p style="font-size: small; color: orange;">
            {{ $t('DeleteCurrentPhoto') }}
          </p>
        </b-form-group>

      </b-form>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          :disabled="loading"
          class="buttonSubmit"
          @click="onSubmit"
        >
          <div
            v-if="loading"
            class="spinner-border spinner-border-sm"
          />
          {{ $t('Submit') }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate';
// import {
//   required, numeric, minValue,
// } from 'vuelidate/lib/validators';
import AddFile from '@/modals/AddFile.vue';
import { client } from '../domainConfig';

export default {
  components: {
    AddFile,
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: {
    materialVariant: {
      type: Object,
      default: () => { return {} },
    },
    materialName: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop
    image: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/require-prop-types
    idMat: {
    },
  },
  data() {
    return {
      loading: false, // Re-enable the button
      page: 1,
      client: client.clientName,
      pageP: 1,
      pageC: 1,
      pageSize: 15,
      totalItems: '',
      options: [],
      selected: null,
      isActive: true,
      form: {
        materialVariantName: null,
        description: null,
        colorId: null,
        minimalStockAmount: null,
        pricePerMeter: null,
        sellPrice: null,
        daysToDeliver: null,
        mainSupplierId: null,
        secondSupplierId: null,
        locationId: null,
        colorName: null,
        totalStockAmount: null,
        measurementType: null,
        locationName: null,
      },
      supplierSecond: null,
      supplierOne: null,
      deleteButton: false,
      photoId: null,
      isTextShown: false,
      hoveredIndex: null,
      text: 'Click to zoom the photo',
    }
  },
  // validations: {
  //   form: {
  //     mainSupplierId: {
  //       required,
  //     },
  //     // secondSupplierId: {
  //     //   required,
  //     // },
  //     colorDataId: {
  //       required,
  //     },
  //     materialVariantName: {
  //       required,
  //       // minLength: minLength(3),
  //       // alpha,
  //     },
  //     description: {
  //       // required,
  //       // minLength: minLength(3),
  //       // alpha,
  //     },
  //     measurementType: '',
  //     total: {
  //       // numeric,
  //       required,
  //       minValue: minValue(0),
  //     },
  //     minimalStockAmount: {
  //       required,
  //       // numeric,
  //       minValue: minValue(0),
  //     },
  //     pricePerMeter: {
  //       required,
  //       minValue: minValue(0),
  //       // numeric,
  //     },
  //     daysToDeliver: {
  //       required,
  //       minValue: minValue(0),
  //       numeric,
  //     },
  //   },
  // },
  computed: {
    ...mapGetters(['getLocationByPagination', 'getMaterialVariantById', 'getSuppliers', 'getColorsData', 'getTotalItemsForSuppliers', 'getCurrentPageForSuppliers', 'getFilteredSupplierInSelect', 'getTotalItemsForColors', 'getCurrentPageColors']),
    materialVariantById() {
      return this.$store.getters.getMaterialVariantById;
    },
  },
  watch: {
    materialVariantById: {
      handler(newVal) {
        this.form = {
          materialVariantName: newVal.materialVariantName,
          description: newVal.description,
          colorId: newVal.colorId,
          minimalStockAmount: newVal.minimalStockAmount,
          pricePerMeter: newVal.pricePerMeter,
          sellPrice: newVal.sellPrice,
          daysToDeliver: newVal.daysToDeliver,
          mainSupplierId: newVal.mainSupplierId,
          secondSupplierId: newVal.secondSupplierId,
          fileName: [],
          locationId: newVal.locationId,
          colorName: newVal.colorName,
          totalStockAmount: newVal.totalStockAmount,
          measurementType: newVal.measurementType,
          locationName: newVal.locationName,
          mainSupplierName: newVal.mainSupplierName,
          secondSupplierName: newVal.secondSupplierName,
        }
        console.log('Objekti', newVal)
      },
    },
    // eslint-disable-next-line func-names
    'form.locationName': function (value) {
      if (value === null) {
        this.form.locationId = null;
        this.form.locationName = null;
      } else if (typeof value === 'object' && value.locationId !== undefined) {
      // Update the form properties with locationName and locationId
        this.form.locationId = value.locationId;
        this.form.locationName = value.locationName;
      }
    },
    // eslint-disable-next-line func-names
    'form.colorName': function (value) {
      if (value === null) {
        this.form.colorId = null;
        this.form.colorName = null;
      } else if (typeof value === 'object' && value.id !== undefined) {
      // Update the form properties with colorName and colorId
        this.form.colorId = value.id;
        this.form.colorName = value.colorName;
      }
    },
    'form.mainSupplierName': function (value) {
      if (value === null) {
        this.form.mainSupplierId = null;
        this.form.mainSupplierName = null;
      } else if (typeof value === 'object' && value.supplierId !== undefined) {
      // Update the form properties with mainSupplierName and colorId
        this.form.mainSupplierId = value.supplierId;
        this.form.mainSupplierName = value.supplierName;
      }
    },
    'form.secondSupplierName': function (value) {
      if (value === null) {
        this.form.secondSupplierId = null;
        this.form.secondSupplierName = null;
      } else if (typeof value === 'object' && value.supplierId !== undefined) {
      // Update the form properties with secondSupplierName and colorId
        this.form.secondSupplierId = value.supplierId;
        this.form.secondSupplierName = value.supplierName;
      }
    },

    // supplierOne(value) {
    //   if (value === null) {
    //     this.form.mainSupplierId = null;
    //   } else if (typeof value === 'object' && value.supplierId !== undefined) {
    //     this.form.mainSupplierId = value.supplierId;
    //   }
    // },
    // supplierSecond(value) {
    //   if (value === null) {
    //     this.form.secondSupplierId = null;
    //   } else if (typeof value === 'object' && value.supplierId !== undefined) {
    //     this.form.secondSupplierId = value.supplierId;
    //   }
    // },

  },
  mounted() {
    // this.getColors()
    // this.form = this.materialVariant
  },
  methods: {
    ...mapActions(['getMaterialVariantById_new', 'loadSuppliers', 'loadFilSByName', 'resetFilS', 'loadColors', 'deleteImageMaterialVariant']),
    validateState(materialVariantName) {
      const { $dirty, $error } = this.$v.form[materialVariantName];
      return $dirty ? !$error : null;
    },
    handleDeleteClick(test) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this photo?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        cancelVariant: 'none',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          if (value) {
            this.deletePhoto(test);
          }
        })
        .catch((err) => {
          console.log('Error in msgBoxConfirm:', err);
        });
    },
    async deletePhoto(value) {
      await this.deleteImageMaterialVariant({
        id: value,
        successCallback: () => {
          this.$emit('getPhotos', this.form)
        },
      })
    },
    zoomIn(index) {
      this.hoveredIndex = index;
    },
    zoomOut() {
      this.hoveredIndex = null;
    },
    showText() {
      this.isTextShown = true;
    },
    async onSubmit() {
      try {
        this.loading = true; // Disable the button
        await this.$refs.addFile.uploadFile();

        this.$emit('editMatVar', this.form);
        this.$refs.modal.hide();
        this.onReset();
      } catch (error) {
        console.error('An error occurred during file upload:', error);
        this.loading = false;
      } finally {
        this.loading = false; // Disable the button
        this.form.fileName = [];
      }
    },
    onCancel() {
      this.$refs.modal.hide()
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
      this.onReset()
    },
    onReset() {
      this.form.materialVariantName = '';
      this.form.description = '';
      this.form.colorId = '';
      this.form.colorName = '';
      this.form.measurementType = '';
      this.form.totalStockAmount = '';
      this.form.minimalStockAmount = '';
      this.form.pricePerMeter = '';
      this.form.sellPrice = '';
      this.form.daysToDeliver = '';
      this.form.mainSupplierId = '';
      this.form.secondSupplierId = '';
      this.form.locationId = ''
      this.form.locationName = ''
      this.form.fileName = [];
      this.fileData = []
      this.isTextShown = false
      this.hoveredIndex = null
      this.loading = false;
    },
    async getSupplierPaginated(page, pageS) {
      await this.loadSuppliers({
        pageNumber: page,
        pageSize: pageS,
        supplierName: null,
        contactName: null,
        isActive: true,
      });
    },

    nextSuppliers() {
      if (this.pageP < this.getTotalItemsForSuppliers) {
        this.pageP += 1;
        this.getSupplierPaginated(this.pageP, this.pageSize);
      }
    },
    prevSuppliers() {
      if (this.pageP > 1) {
        this.pageP -= 1;
        this.getSupplierPaginated(this.pageP, this.pageSize);
      }
    },
    async onSearchSuppliers(query) {
      await this.loadFilSByName({ companyName: query, isActive: this.isActive });
      if (query == '') {
        this.resetFilS()
        this.getSupplierPaginated(this.getCurrentPageForSuppliers, this.pageSize);
      }
    },
    nextColors() {
      if (this.pageC < this.getTotalItemsForColors / 10) {
        this.pageC += 1;
        this.loadColors({
          colorName: '',
          pageSize: 15,
          pageNumber: this.pageC,
        });
      }
    },
    prevColors() {
      if (this.pageC > 1) {
        this.pageC -= 1;
        this.loadColors({
          colorName: '',
          pageSize: 15,
          pageNumber: this.pageC,
        });
      }
    },
    async onSearchColors(query) {
      await this.loadColors({ colorName: query, pageNumber: 1, pageSize: 15 });
      if (query == '') {
        this.loadColors({ colorName: query, pageNumber: this.getCurrentPageColors, pageSize: 15 });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.hidden{
  margin-bottom: 0px !important;
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.flex-form {
  display: flex;
  flex-direction: column;
}
.photo{
    margin-left: 5px;
    height: 50px;
    border-radius: 8px;
  }

  .hovered {
  /* Additional styles for the hovered photo */
  transform: scale(7.2);
  border-radius: 0px;
}
.second-photo{
  margin-left: 20px;
}
.third-photo{
  margin-left: 20px;
}

</style>
