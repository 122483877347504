<template>
  <div>
    <b-modal
      id="modal-remove-material-variant"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('RemoveMaterial') "
      hide-footer
    >
      <b-form
        @submit="onSubmit"
      >

        <div>
          <p>{{ $t('Areyousureyouwanttodelete') }} {{ materialVartiantNameData }}</p>
          <b-button
            type="submit"
            variant="danger"
            class="buttonCancel"
          >
            {{ $t('Delete') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['materialName', 'materialEditItem'],

  data() {
    return {
      materialVartiantNameData: '',
      form: {
        stock: '',
      },
    }
  },
  watch: {
    materialEditItem() {
      this.fillName()
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.$emit('deleteMatVar', 'delete')
      this.$refs.modal.hide()
    },
    fillName() {
      this.materialVartiantNameData = this.materialEditItem.materialVariantName
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
</style>
