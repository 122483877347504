<template>
  <div>
    <!-- <Navbar />
    <Sidebar /> -->
    <section class="main ">

      <MatsListing
        :rows-length-data="materialLength"
        @sendMaterial="materialId"
      />
      <Table
        :material-id="materialIdToShow"
        :measure-id="materialMeasure"
        :material-name="materialName"
        @rowsLength="rowsLengthItem"
      />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/sidebars/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import Table from '@/components/Table.vue'
import MatsListing from '@/components/MatsListing.vue'
import { mapGetters } from 'vuex'


export default {
  name: 'Home',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Sidebar,
    // eslint-disable-next-line vue/no-unused-components
    Navbar,
    Table,
    MatsListing,
  },
  data() {
    return {
      materialIdToShow: '',
      materialMeasure: '',
      materialName: '',
      materialLength: '',
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),
  },
  update() {
    this.materialId()
  },
  methods: {
    rowsLengthItem(length) {
      this.materialLength = length
    },
    materialId(item) {
      this.materialIdToShow = item.materialId
      this.materialMeasure = `${item.measurementType}s`
      this.materialName = item.name
    },
  },
}
</script>
<style scoped lang="scss">
 @media screen and (max-width: 1400px) {
  .main {
    flex-direction: column;
  }
  }

@media screen and (max-width: 1500px){
  .main{
    padding: 60px 15px 15px 90px;
  }
}
 @media screen and (max-width: 1190px) {
  .main {
        padding: 90px 0.75rem 15px 0.75rem;
  }
 }
</style>
