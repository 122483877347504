<template>
  <div>
    <b-modal
      id="modal-add-stock"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('AddStock')}: ` + materialNameAddSub + ',' + ' Color: ' + colorNameAddSub"
      hide-footer
      @close="onCancel"
    >
      <b-form class="form">
        <b-form-group
          id="input-group-1"
          :label="`${$t('Amount')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.form.stock.$model"
            type="number"
            :state="validateState('stock')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('TheValue') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="`${$t('TotalPrice')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-2"
            v-model="$v.form.totalPrice.$model"
            type="number"
            :state="validateState('totalPrice')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('FillData') }}</b-form-invalid-feedback>
        </b-form-group>
      </b-form>
      <b-form-group
        :label="$t('Supplier')"
        style="width: 100%;"
      >
        <vue-select
          v-model="form.supplierId"
          :options="getSupplierDropDown"
          label="supplierName"
          :reduce="(e) => e.supplierId"
          :placeholder="$t('Main')"
          aria-describedby="input-1-live-feedback"
          :clearable="false"
        />
        <b-form-invalid-feedback
          id="input-1-live-feedback"
        >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        :label="`${$t('Notes')}:`"
        label-for="input-1"
      >
        <b-form-textarea
          id="textarea"
          v-model="$v.form.notes.$model"
          rows="3"
          max-rows="10"
          :state="validateState('notes')"
          aria-describedby="input-1-live-feedback"
        />
        <b-form-invalid-feedback
          id="input-1-live-feedback"
        >{{ $t('FillData') }}</b-form-invalid-feedback>
      </b-form-group>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          style="background: #FF274F !important;"

          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, minValue,
} from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  props: {
    colorNameAddSub: {
      type: String,
      required: true,
    },
    materialNameAddSub: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        stock: null,
        notes: '',
        totalPrice: null,
        supplierId: null,
      },
    }
  },
  validations: {
    form: {
      stock: {
        required,
        // numeric,
        minValue: minValue(0),
      },
      notes: {
        required,
      },
      totalPrice: {
        required,
        minValue: minValue(0),
      },
    },
  },
  computed: {
    ...mapGetters(['getSupplierDropDown']),
  },
  mounted() {
    this.suppliersDropDown({
      isActive: true,
    })
  },
  methods: {
    ...mapActions(['suppliersDropDown']),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit('addedStock', this.form)
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset()
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset()
    },
    onReset() {
      this.form.stock = 0;
      this.form.notes = '';
      this.form.totalPrice = 0;
      this.form.supplierId = null;
    },
  },
}
</script>

<style lang="scss" scoped>
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .form-group {
    margin-bottom: 5px;
  }
  // .flex-form {
  //   display: flex;
  //   flex-direction: column;
  // }
</style>
