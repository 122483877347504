<template>
  <div>
    <b-modal
      id="add-variant-cart"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('AddVariantCart')"
      size="l"
      hide-footer
      @close="onCancel"
      @shown="handleModalShown"
    >
      <b-form class="vacation">
        <b-form-group
          v-if="getIsInCart== false"
          id="input-group-8"
          :label="$t('Amount')"
          label-for="input-25"
        >
          <b-form-input
            id="input-8"
            v-model.number="form.amount"
            type="number"
          />
        </b-form-group>
        <b-form-group
          v-if="getIsInCart== true"
          id="input-group-8"
          label-for="input-25"
        >
          <div>
            {{ this.$t('MaterialVariant') + ' ' }}
            <span style="font-weight: bold;">{{ addedCart.materialVariantName }}</span>
            {{ ' ' + this.$t('IsAlreadyInCart') }}
          </div>
        </b-form-group>
      </b-form>

      <div class="buttonsEverywhere">
        <b-button
          v-if="getIsInCart== false"
          type="button"
          class="buttonSubmit"
          :disabled="form.amount == ''"
          @click="onSubmit"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          v-if="getIsInCart== true"
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="goToCart()"
        >
          {{ $t("GoToCart") }}
          <b-icon
            icon="cart"
            class="mr-1"
          />
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px;
                background: white;
                color: black;
                border-color: white;
                font-weight: 400;"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import 'vue2-datepicker/index.css';

import { client } from '@/domainConfig'


export default {
  components: {
  },
  mixins: [validationMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    addedCart: {
      type: Object,
    },
  },
  data() {
    return {
      client: client.clientName,
      form: {
        amount: '',
        mainSupplierId: null,
        materialVariantId: null,
        orderedPrice: null,
      },
    };
  },

  computed: {
    ...mapGetters([
      'getLoggedInUser',
      'getIsInCart',
    ]),
  },
  watch: {
  },
  created() {
  },
  methods: {
    ...mapActions([
    ]),
    goToCart() {
      this.$router.push('/cart');
    },
    handleModalShown() {
    },
    async onSubmit() {
      this.form.materialVariantId = this.addedCart.materialVariantId;
      this.form.orderedPrice = this.addedCart.pricePerMeter;
      this.form.mainSupplierId = this.addedCart.mainSupplierId;
      this.$emit('pushToCart', this.form);
      this.$refs.modal.hide();
      this.onReset();
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
      this.form.amount = ''
    },
  },
};
</script>

    <style lang="scss" scoped>
    form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
    .form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
    .vacation {
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: 1fr;
    }
    .form2 {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
    .flex-form {
      display: flex;
      flex-direction: column;
    }
    .uploadButtons {
      display: flex;
      align-items: flex-end;
    }
    </style>
